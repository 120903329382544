import React, { useState, useEffect } from 'react';
import VehicleInfo from './VehicleInfo';
import Carrier from './Carrier';
import { fetchData } from '../services/dataService';

const Route = ({ routeData }) => {

    const footerStyle = {
        color: "black",
        fontSize: "15px",
        fontWeight: "bold"
    };
    routeData.carrier.amount = routeData.carrier.amount!=null?routeData.carrier.amount:routeData.vehicles.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.amount;
    }, 0);

    routeData.carrier.prevAmount = routeData.carrier.prevAmount!=null?routeData.carrier.prevAmount:routeData.vehicles.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.prevAmount;
    }, 0);

    return (
        <div>

            <div style={{
                display: "flex", justifyContent: "space-between", width: "100%"
            }}>
                <div style={{ display: 'flex', paddingTop: "10px", width: "100%" }}>

                    {routeData.vehicles.length > 0 ? (
                        routeData.vehicles.map((vehicle, index) => (
                            <React.Fragment key={index}>
                                <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', height: "220px" }}>
                                    <div style={{ marginTop: "auto" }}>
                                        <VehicleInfo info={vehicle} />
                                    </div>
                                </div>
                            </React.Fragment>
                        ))
                    ) : (
                        <p>Загрузка данных...</p>
                    )}


                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', width: "100%"}}>
                        <div >
                            <Carrier carrier={routeData.carrier} />
                        </div>
                    </div>
                </div>

            </div>
            <div style={{
                display: "flex", justifyContent: "space-between", marginTop:10, width: "100%"
            }}>
                <span style={footerStyle}>{routeData.name}</span>
                <span style={footerStyle}>{routeData.carrier.name}</span>
            </div>
        </div >
    );
};

export default Route;