import React, { useState, useEffect } from 'react';


const QRPayment = () => {



    return (
        <div>

QR
        </div>
    );
};

export default QRPayment;