import logo from './logo.svg';
import React, { useRef, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Monitoring from './components/Monitoring';
import QRPayment from './components/qrpay/QRPayment';
import QRTicket from './components/qrpay/QRTicket';
import PaymentGateWay from './components/qrpay/PaymentGateWay';
import FailPayment from './components/qrpay/FailPayment';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {

    const appRef = useRef(null);

    useEffect(() => {
        if (appRef.current) {
            //appRef.current.style.transform = 'scale(0.8)';
            //appRef.current.style.transformOrigin = 'top left';
        }
    }, []);

    return (
        <div className="App">
            <main ref={appRef}>

                <Router>
                    <Routes>
                        <Route path="/" element={<Monitoring/>} />
                        <Route path="/monitoring" element={<Monitoring/>} />
                        <Route path="/qrpay" element={<QRPayment/>} />
                        <Route path="/pay/:qr" element={<PaymentGateWay/>} />
                        <Route path="/pay/ticket/:id" element={<QRTicket/>} />
                        <Route path="/pay/fail/:id" element={<FailPayment/>} />
                        <Route path="*" element={<QRPayment/>} />
                    </Routes>
                </Router>
            </main>
        </div>
    );
}

export default App;