import React, { useState, useEffect } from 'react';
import PassengersInfo from './PassengersInfo';
import InspectorList from './InspectorList';
import RouteList from './RouteList';
import CarriersList from './CarriersList';

import { fetchData } from '../services/dataService';

const Monitoring = () => {
    const [drivers, setData] = useState([]);

    useEffect(() => {
        const getData = async () => {
            const data = await fetchData();
            setData(data);
        };

        // Первоначальная загрузка данных
        getData();

        // Установка интервала для обновления данных
        const intervalId = setInterval(getData, 3000);

        // Очистка интервала при размонтировании компонента
        return () => clearInterval(intervalId);
    }, []);


    return (
        <div style={{ paddingLeft: "50px", paddingRight: "50px" }}>

            <RouteList routes={drivers.routes} />
            <div style={{
                display: "flex", justifyContent: "space-between", width: "100%"
            }}>
                <div style={{ display: 'flex', paddingTop: "10px", width: "100%" }}>
                    <div style={{ width: "90%" }}>
                        <InspectorList list={drivers.inspectors} />
                    </div>
                    <div>
                        {drivers.passengersInfo != null && <PassengersInfo data={drivers.passengersInfo} />}
                    </div>
                </div>
            </div>
            <CarriersList list={drivers.carriers} />

        </div>
    );
};

export default Monitoring;