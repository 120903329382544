import React from 'react';

const Barell = ({ size, inPlus, value }) => {
    const k = 0.5;

    const infoStyle = {
        width: '40px',
        height: '100px',
        border: '0px solid black'
    };
    const burStyle = {
        width: size * k,
        //height: size*1*k,

    };
    const img = inPlus ? "./barrel_green.png" : "./barrel_red.png";

    return (
        <div style={infoStyle}>
            <div style={{ width: '100%', textAlign: 'center', border: '0px solid black' }}>
                {value > 0 && <div>{value}</div>}
                <img src={img} style={burStyle} />
            </div>
        </div>
    );
};

export default Barell;