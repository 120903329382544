import React from 'react';
import BarrelsChart from './BarrelsChart';
import Inspector from './Inspector';

const VehicleInfo = ({ info }) => {

    const infoStyle = {
        width: '210px',
        height: '80px',
        display: 'flex'
    };

    const tymeStyle = {
        marginLeft: "20px"
    };
    const textStyle = {
        position: "absolute",
        top: "20px",
        left: "50%",
        transform: "translate(-50%, -50%)",
        color: "black",
        fontSize: "15px",
        fontWeight: "bold"
    };

    const img = (info.openTime == null || info.closeTime != null) ? "./bus_gray.png" : info.amount > info.prevAmount ? "./bus_green.png" : "./bus_red.png";

    return (
        <div>
            <div style={tymeStyle}>
                {info.inspector != null && <Inspector data={info.inspector} active={true} />}
                {info.openTime == null ? "." : info.openTime}
            </div>


            <span style={infoStyle}>
                <div style={{ position: 'relative', display: 'inline-block' }}>
                    <img src={img} style={{ width: 80, height: 80 }} />
                    <div style={textStyle}>{info.num}</div>
                </div>
                <BarrelsChart prev={info.prevAmount} current={info.amount} />
            </span>
            <div style={tymeStyle}>
                {info.closeTime == null ? "." : info.closeTime}
            </div>
        </div>
    );
};

export default VehicleInfo;