import React from 'react';
import Barrel from './Barrel';

const BarellsChart = ({ prev, current }) => {

    var b1Size = null;
    var b2Size = null;
    if (prev == 0 && current > 0) {
        b1Size = 0;
        b2Size = 100;
    } else if (current == 0 && prev > 0) {
        b1Size = 100;
        b2Size = 0;
    } else if (prev == 0 && current == 0) {
        b1Size = 0;
        b2Size = 0;
    } else {
        const max = prev > current ? prev : current;
        const min = prev < current ? prev : current;
        //const r = (min * 100)/max;
        var k = 100 / Math.sqrt(max / min);
        b1Size = prev > current ? 100 : k;
        b2Size = prev < current ? 100 : k;
    }

    return (
        <div style={{ display: 'flex' }}>

            {prev != null && prev > 0 && <Barrel size={b1Size != null ? b1Size : 100} value={prev} inPlus={false} />}
            {current != null && <Barrel size={b2Size != null ? b2Size : 100} value={current} inPlus={true} />}
        </div>
    );
};

export default BarellsChart;