import React, { useState, useEffect } from 'react';
import Carrier from './Carrier';
import { fetchData } from '../services/dataService';

const CarriersList = ({ list }) => {

    return (
        <div>
            <div style={{
                display: "flex", justifyContent: "space-between", width: "100%"
            }}>
                <div style={{ display: 'flex', paddingTop: "10px", width: "100%" }}>
                    {list != null && list.length > 0 ? (
                        list.map((item, index) => (
                            <React.Fragment key={index}>
                                <div style={{width: "220px"}}>
                                    <Carrier carrier={item} isShowName={true} />
                                </div>
                            </React.Fragment>
                        ))
                    ) : (
                        <p>Загрузка данных...</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CarriersList;