import React from 'react';
import Barrel from './Barrel';
import BarrelsChart from './BarrelsChart';

const Passanger = ({ count, isRabbit }) => {
    const img = isRabbit ? "./rabbit_red.png" : "./cat_green.png";
    return (
        <div>
            <div>{count}</div>
            <img src={img} style={{width:"40px"}}/>
        </div>
        
    );
};

export default Passanger;