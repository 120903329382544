import React from 'react';
import Spinner from 'react-bootstrap/Spinner';


const LoadingIndicator = ({text}) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
            <Spinner animation="border" role="status"     style={{
      height: '10rem',
      width: '10rem'
    }}>
                <span className="sr-only">{text}</span>
            </Spinner>
        </div>
    );
};

export default LoadingIndicator;