import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useParams } from 'react-router-dom';



const QRTicketInfo = ({ticket}) => {
    const { id } = useParams();
    const [payData, setPayData] = useState('');

    //для проверки выполнеения только один раз
    const hasFetched = useRef(false);

    useEffect(() => {
        if (!hasFetched.current) {
            hasFetched.current = true;
            async function fetchData() {

            }

            fetchData();
        }
    }, []); // Пустой массив зависимостей

    return (
        <div className="container">
            <div className="item">
                <div className="description">Маршрут:</div>
                <div className="value">{ticket.routeName}</div>
            </div>
            <div className="item">
                <div className="description">Билет:</div>
                <div className="value">{ticket.num}</div>
            </div>
            <div className="item">
                <div className="description">Стоимость:</div>
                <div className="value">{ticket.amount} {ticket.currency}</div>
            </div>
            <div className="item">
                <div className="description">Карта:</div>
                <div className="value">{ticket.payNum}</div>
            </div>
        </div>
    );
}



export default QRTicketInfo;