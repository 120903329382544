import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, useParams } from 'react-router-dom';
import { getPaymentData } from './PaymentService';
import QRTicketInfo  from './QRTicketInfo';

import ErrorMessage from '../ErrorMessage';
import './css/ticket.css';
const FailPayment = () => {
    const { id } = useParams();
    const [payData, setPayData] = useState('');

    //для проверки выполнеения только один раз
    const hasFetched = useRef(false);

    useEffect(() => {
        if (!hasFetched.current) {
            hasFetched.current = true;
            async function fetchData() {
                const payment = await getPaymentData(id);
                //console.log("payment: ", payment)
                setPayData(payment);
            }

            fetchData();
        }
    }, []);

    return (
        <div>
            <ErrorMessage message="Платеж не проведен"/>
            {payData.ticket!=null &&<QRTicketInfo ticket={payData.ticket}/>}
        </div>
    );
}



export default FailPayment;