import {  API_URL_AQ_PAY_UBQ, API_URL_AQ_PAY_PAYMENT } from '../../config';


export const getPaymentUrl = async (qr) => {
    try {
        const response = await fetch(API_URL_AQ_PAY_UBQ+qr, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        });
        if (!response.ok) {
            //throw new Error('Ошибка при получении данных');
        }
        //console.log("response: "+response.text();
        const data = await response.json();
        return data;
    } catch (error) {
        console.error(error);
        return [];
    }
};

export const getPaymentData = async (qr) => {
    try {
        const response = await fetch(API_URL_AQ_PAY_PAYMENT+qr, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        });
        if (!response.ok) {
            //throw new Error('Ошибка при получении данных');
        }
        //console.log("response: "+response.text();
        const data = await response.json();
        return data;
    } catch (error) {
        console.error(error);
        return [];
    }
};