import React from 'react';
import Alert from 'react-bootstrap/Alert';

const ErrorMessage = ({ message }) => {
    return (
        /** 
        <div style={{ color: 'red', fontWeight: 'bold', border: '1px solid red', padding: '10px', borderRadius: '5px' }}>
            {message}
        </div>
        */
        <div>
            <Alert variant="danger">
                {message}
            </Alert>
        </div>
    );
};

export default ErrorMessage;