import React, { useState, useEffect } from 'react';
import Passanger from './Passanger';

const PassengersInfo = ({ data }) => {

    return (
        <div>
            <Passanger count={data.passengers} isRabbit={false} />
            <Passanger count={data.rabbits} isRabbit={true} />

        </div>
    );
};

export default PassengersInfo;