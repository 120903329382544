import React, { useState, useEffect } from 'react';
import Route from './Route';
import { fetchData } from '../services/dataService';

const RouteList = ({ routes }) => {

    return (
        <div>

            {routes != null && routes.length > 0 ? (
                routes.map((routeData, index) => (
                    <React.Fragment key={index}>
                        <Route routeData={routeData} />
                        <div style={{
                            width: '100%',
                            height: '2px',
                            backgroundColor: 'black'}}>
                                
                            </div>
                    </React.Fragment>
                ))
            ) : (
                <p>Загрузка данных...</p>
            )}

        </div>
    );
};

export default RouteList;