import React from 'react';
import BarrelsChart from './BarrelsChart';

const Carrier = ({ carrier, isShowName }) => {

    const nameStyle = {
        color: "black",
        fontSize: "15px",
        fontWeight: "bold"
    };


    return (
        <div>
            <div style={{ textAlign: 'left', display: "flex" }}>
                <img src="./jp_green.png" />
                <BarrelsChart prev={carrier.prevAmount} current={carrier.amount} />

            </div>
            {isShowName && <div style={ nameStyle }>{carrier.name}</div>}
        </div>

    );
};

export default Carrier;