import React from 'react';
import Barrel from './Barrel';
import BarrelsChart from './BarrelsChart';

const Inspector = ({ data, active }) => {
    const img = active?"./empl_green.png": (data.shiftClose != null || (data.shiftOpen == null)) ? "./empl_gray.png" :  ((data.routeOpen != null) && (data.routeClose == null)) ? "./empl_green.png" : "./empl_red.png";
    return (
        <div>
            <div style={{height: "20px", whiteSpace:'nowrap', overflow: 'hidden', textOverflow:'clip', border: '0px solid black' }}>{data.name}</div>
            <img src={img} style={{width:"40px"}}/>
            {data.validations != null && <div>{data.validations}</div>}
        </div>
        
    );
};

export default Inspector;