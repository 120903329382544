// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
display: flex;
flex-direction: column;
width: 300px;
margin: 0 auto;
}

.item {
display: flex;
justify-content: space-between;
padding: 10px;
border-bottom: 1px solid #ccc;
}

.item:last-child {
border-bottom: none;
}

.description {
font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/qrpay/css/ticket.css"],"names":[],"mappings":"AAAA;AACA,aAAa;AACb,sBAAsB;AACtB,YAAY;AACZ,cAAc;AACd;;AAEA;AACA,aAAa;AACb,8BAA8B;AAC9B,aAAa;AACb,6BAA6B;AAC7B;;AAEA;AACA,mBAAmB;AACnB;;AAEA;AACA,iBAAiB;AACjB","sourcesContent":[".container {\r\ndisplay: flex;\r\nflex-direction: column;\r\nwidth: 300px;\r\nmargin: 0 auto;\r\n}\r\n\r\n.item {\r\ndisplay: flex;\r\njustify-content: space-between;\r\npadding: 10px;\r\nborder-bottom: 1px solid #ccc;\r\n}\r\n\r\n.item:last-child {\r\nborder-bottom: none;\r\n}\r\n\r\n.description {\r\nfont-weight: bold;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
