import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useParams } from 'react-router-dom';
import ErrorMessage from '../ErrorMessage';
import LoadingIndicator from '../LoadingIndicator';
import { getPaymentUrl } from './PaymentService';

const PaymentGateWay = () => {
    const { qr } = useParams();

    const [error, setError] = useState([]);
    const [hasData, setHasData] = useState([]);

    //для проверки выполнеения только один раз
    const hasFetched = useRef(false);

    useEffect(() => {
        setHasData(false);
        if (!hasFetched.current) {
            hasFetched.current = true;
            async function fetchData() {
                const data = await getPaymentUrl(qr);
                console.log("data: ", data)

                if (data.code == 0) {
                    //setError(null);
                    window.location.href = data.url;
                } else {
                    setError(data.message);
                }
                setHasData(true); 
            }

            fetchData();
        }
    }, []); // Пустой массив зависимостей

    return (
        <div>
            {error && error!='' && <ErrorMessage message={error}/>}
            {hasData == false &&  <LoadingIndicator text="Loading..."/>}
        </div>
    );
};



export default PaymentGateWay;